@import url("https://fonts.googleapis.com/css2?family=Katibeh&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Monoton&display=swap");

h1 {
  /* border: 1px solid red; */
  font-family: "Monoton", sans-serif;
  font-size: 4rem;
  color: var(--title-name-buttons-color);
  line-height: 70%;
  /* margin-bottom: 35px; */
}

h4 {
  margin-bottom: 25px;
}

a {
  color: var(--title-name-buttons-color);
  text-decoration: none;
}

a:hover {
  color: var(--main-buttons-hover-background-color);
}

.App {
  /* box-shadow: 0px 0px 0px 1px red; */
  transition: all 0.25s linear;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input-container {
  /* box-shadow: 0px 0px 0px 1px purple; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name-input-text {
  margin: 0px;
  margin-bottom: 10px;
  font-size: 1.75rem;
}

.name-input {
  border: 0px;
  box-shadow: 0px 0px 0px 1px var(--body-text-color);
  border-radius: 15px;
  width: 70%;
  font-size: 1.75rem;
  line-height: 1;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: var(--body-text-color);
  font-weight: 400;
  background: transparent;
}

.name-input:focus {
  border: 0px;
  box-shadow: 0px 0px 2px 2px var(--body-text-color);
  outline: none;
}

.main {
  /* border: 1px solid green; */
  display: flex;
  gap: 20px;
}

.modal-content {
  background-color: var(--body-background-color);
  color: var(--body-text-color);
}

/* LEFT SIDE INFO-PANEL */

.info-panel {
  font-size: 1.5rem;
  width: 250px;
  /* box-shadow: 0px 0px 0px 1px purple; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.name {
  /* font-family: "Monoton", sans-serif; */
  /* box-shadow: 0px 0px 0px 1px green; */
  font-size: 2rem;
  font-variant: small-caps;
  width: 250px;
  line-height: 1;
  color: var(--title-name-buttons-color);
}

.info-item {
  /* box-shadow: 0px 0px 0px 1px yellow; */
  margin: 0;
}

/* MIDDLE CUBE-PANEL */

.cube-panel {
  /* box-shadow: 0px 0px 0px 1px red; */
  height: 280px;
  width: 250px;
}

.score-message {
  /* box-shadow: 0px 0px 0px 1px green; */
  font-size: 1.3rem;
  margin: 0px;
}

.game-over {
  /* box-shadow: 0px 0px 0px 1px blue; */
  display: flex;
  height: 280px;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.game-over-svg {
  /* box-shadow: 0px 0px 0px 1px red; */
  max-height: 100px;
}

/* Right SIDE CONTROL-PANEL */

.control-panel {
  width: 250px;
  /* box-shadow: 0px 0px 0px 1px orange; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
}

.right-menu-button {
  border: 1px solid var(--title-name-buttons-color);
  border-radius: 7px;
  background: transparent;
  color: var(--title-name-buttons-color);
  width: 150px;
  height: 40px;
  transition: border, color 0.25s ease-out;
  /* margin: 5px; */
}

.right-menu-button:disabled {
  border: 1px solid var(--main-buttons-disabled-color);
  color: var(--main-buttons-disabled-color);
}

.right-menu-button:hover:not([disabled]),
.right-menu-button:focus:not([disabled]) {
  outline: none;
  color: var(--main-buttons-hover-text-color);
  background: var(--main-buttons-hover-background-color);
}

/* .info-button:disabled {
  border: 1px solid var(--info-button-disabled-color);
  color: var(--info-button-disabled-color);
}

.info-button:hover:not([disabled]),
.info-button:focus:not([disabled]) {
  outline: none;
  background: var(--info-button-hover-background-color);
  color: var(--info-button-hover-text-color);
} */

@media only screen and (orientation: portrait) {
  h1 {
    font-size: 3.25rem;
  }

  h4 {
    margin-bottom: 15px;
  }

  .main {
    flex-direction: column;
    gap: 0px;
  }

  /* LEFT SIDE INFO-PANEL */

  .info-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 1rem;
    gap: 0px;
  }

  .name {
    font-size: 2rem;
    font-variant: small-caps;
    line-height: 1;
  }

  .info-item {
    /* box-shadow: 0px 0px 0px 1px green; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 101px;
    margin: 0;
  }

  .control-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 1rem;
    gap: 0px;
    margin-top: 20px;
  }

  .right-menu-button {
    border: 1px solid var(--title-name-buttons-color);
    border-radius: 7px;
    background: transparent;
    color: var(--title-name-buttons-color);
    width: 125px;
    transition: border, color 0.25s ease-out;
    /* margin: 5px; */
  }
}

@media only screen and (orientation: portrait) and (max-height: 720px) {
  h1 {
    font-size: 2.75rem;
    margin-bottom: 5px;
  }

  h4 {
    margin-bottom: 10px;
  }

  .name {
    font-size: 1.5rem;
    line-height: 1;
  }

  .info-item {
    height: 25px;
  }

  .cube-panel {
    height: 200px;
  }

  .score-message {
    /* box-shadow: 0px 0px 0px 1px green; */
    font-size: 1rem;
    margin: 0px;
  }

  .control-panel {
    margin-top: 10px;
  }

  .right-menu-button {
    height: 32px;
    font-size: 0.95rem;
    /* margin: 5px; */
  }

  .game-over {
    height: 208px;
    gap: 5px;
  }

  .right-menu-button {
    height: 28px;
    font-size: 0.9rem;
    /* margin: 5px; */
  }
}

@media only screen and (orientation: landscape) and (max-height: 395px) {
  h1 {
    font-size: 2.75rem;
    margin-bottom: 3px;
  }

  h4 {
    margin-bottom: 0px;
  }
}

@media only screen and (orientation: landscape) and (max-height: 355px) {
  .info-panel {
    height: 208px;
  }

  .cube-panel {
    height: 208px;
  }

  .control-panel {
    height: 208px;
  }

  .right-menu-button {
    width: 125px;
    height: 29.71px;
    font-size: 1.05rem;
    /* margin: 5px; */
  }

  .game-over {
    height: 208px;
    gap: 5px;
  }
}
