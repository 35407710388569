.center {
  text-align: center;
}

.button {
  margin-left: 5px;
  margin-right: 5px;
}

.highscores-table {
  width: 100%;
}

tr:nth-child(even) {
  background-color: var(--highscores-table-stripe);
}

td {
  padding: 5px;
  padding-bottom: 7px;
}

.highscore-name {
  font-variant: small-caps;
  font-size: 1.5rem;
  line-height: 1;
}

tr.highlighted {
  background-color: var(--highscores-table-highlight);
  color: black;
}
