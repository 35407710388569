/* Cube ref: https://stackoverflow.com/questions/36331335/how-to-create-cube-with-only-html-and-css */

.cube {
  /* box-shadow: 0px 0px 5px 2.5px yellow; */
  position: relative;
  width: 202px;
  height: 190px;
  margin: 0px auto;
  margin-top: 60px;
}

.side0 {
  width: 110px;
  height: 110px;
  background: var(--cube-side0-color);
  transform: rotate(45deg) skew(-15deg, -15deg);
  position: absolute;
  left: 46px;
  top: -42px;
  opacity: 1;
  box-shadow: 0px 0px 0px 0px var(--cube-shadow-color);
  transition: box-shadow 0.5s ease-in-out;
}

.side1 {
  width: 100px;
  height: 100px;
  background: var(--cube-side1-color);
  transform: skew(180deg, 150deg);
  position: absolute;
  left: 102px;
  top: 43px;
  opacity: 1;
  box-shadow: 0px 0px 0px 0px var(--cube-shadow-color);
  transition: box-shadow 0.5s ease-in-out;
}

.side2 {
  width: 100px;
  height: 100px;
  background: var(--cube-side2-color);
  transform: skew(180deg, 210deg);
  position: absolute;
  top: 43px;
  opacity: 1;
  box-shadow: 0px 0px 0px 0px var(--cube-shadow-color);
  transition: box-shadow 0.5s ease-in-out;
}

/* Cube side style when turned "on" */
.on {
  opacity: 0.5;
}

/* Cube side style when it's the player's turn */
.player {
  cursor: pointer;
  box-shadow: 0px 0px 5px 2.5px var(--cube-shadow-color);
  transition: box-shadow 0.5s ease-in-out;
}

@media only screen and (orientation: landscape) and (max-height: 355px),
  only screen and (orientation: portrait) and (max-height: 720px) {
  .cube {
    width: 202px;
    height: 117px;
    margin-top: 55px;
    margin-bottom: 5px;
  }

  .side0 {
    width: 80px;
    height: 80px;
    /* width: 110px; */
    /* height: 110px; */
    transform: rotate(45deg) skew(-15deg, -15deg);
    left: 61px;
    top: -42px;
  }

  .side1 {
    width: 71px;
    height: 70px;
    /* width: 100px; */
    /* height: 100px; */
    transform: skew(180deg, 150deg);
    left: 102.5px;
    top: 21px;
  }

  .side2 {
    width: 71px;
    height: 70px;
    /* width: 100px; */
    /* height: 100px; */
    transform: skew(180deg, 210deg);
    left: 29.5px;
    top: 21px;
  }
}
